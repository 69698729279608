



































import { Component, Vue } from "vue-property-decorator";
import { Service } from "@/npservice/service";
import { EmitterHandle } from "@/icodici/Emitter";
import LoggedIn from "@/components/LoggedIn.vue";
@Component({
  components: { LoggedIn }
})
export default class AdminBar extends Vue{
  version = ""
  status: string | null = null
  private stateHandle!: EmitterHandle;
  private timerHandle!: number;

  statusColor() {
    switch (this.status) {
      case "NOT_INITIALIZED":
        return "text-danger";
      case "READY":
        return "text-success";
      default:
        return "text-warning";
    }
  }

  signOut() {
    Service.signOut()
  }
  async mounted() {
    this.stateHandle = Service.serviceState.addListenerAndNotify(s => {
      this.status = s
      this.version = Service.version
      if( s == "NOT_INITIALIZED" ) this.$router.replace("/")
    })
    this.timerHandle = setInterval( () => Service.refreshState(), 10000);
    Service.refreshState();
  }

  beforeDestroy() {
    clearInterval(this.timerHandle)
    this.stateHandle?.unsubscribe()
  }

}
