



















































import { Component, Vue } from "vue-property-decorator";
import { isTerminalStatus, LogRecord, Service, Transaction, TransactionData } from "@/npservice/service";
import AdminBar from "@/components/AdminBar.vue";
import WaitScreen from "@/components/WaitScreen.vue";
import Propline from "@/components/propline.vue";
import { relativeShortDateTime } from "@/icodici/date_formats";
import { centerToast, errorToast, reportErrorToast } from "@/icodici/toasts";
import { timeout } from "@/icodici/tools";
import CustomerLink from "@/components/CustomerLink.vue";

@Component({
  components: { CustomerLink, Propline, WaitScreen, AdminBar }
})
export default class TransactionDetails extends Vue {
  data: TransactionData | null = null;
  transaction: Transaction | null = null;
  videoUrl: string | null = null;
  logRecords: Array<LogRecord> | null = null;
  private timeoutHandle: number | null = null;
  blockUi = false
  private showRecord = false;
  private logRecord: LogRecord | null = null;

  ft(t: number) {
    return relativeShortDateTime(t);
  }

  get waitTitle(): string {
    return this.blockUi ? "processing transaction update..." : "loading transaction details...";
  }

  canRestart() {
    const s = this.transaction?.status;
    console.log(">> ", s)
    return s !== null && (s == "PROFILE_NOT_FOUND" || s == "FAILED" || s == "REJECTED" || s == "BAD_FACE");
  }

  async restart() {
    if (this.canRestart()) {
      this.blockUi = true;
      await reportErrorToast(this, "restart failed", async () => {
        await Service.restartTransaction(this.transaction!.id).then(async () => {
          await timeout(1000);
          await this.reload();
        });
      });
      this.blockUi = false;
    }
  }

  async reload() {
    const id = +this.$route.params.id;
    console.log("reloading transaction", id);
    Service.getTransaction(id).then(t => {
      this.data = t;
      this.transaction = t.transaction;
      if (!isTerminalStatus(t.transaction.status)) {
        if (this.timeoutHandle !== null) clearTimeout(this.timeoutHandle)
        this.timeoutHandle = setTimeout(this.reload, 2000)
      } else
        this.timeoutHandle = null;
    }).catch(_x => {
      errorToast(this.$parent, "failed to access transaction details", "error loading transaction")
      this.$router.replace("/transactions")
    });
    Service.videoUrl(id).then(u => this.videoUrl = u);
    Service.getTransactionLogs(id).then(log => this.logRecords = log);
  }

  async logDetails(record: LogRecord) {
    this.showRecord = true;
    this.logRecord = record;
    console.log(this.logRecord);
  }

  async copyDetailsAndClose() {
    if( this.logRecord !== null ) {
      navigator.clipboard.writeText(JSON.stringify(this.logRecord.jsonData, null, 2))
      this.showRecord = false;
      centerToast(this, "Json data copied to clipboard")
    }
  }

  async mounted() {
    this.reload()
  }

  beforeDestroy() {
    if (this.timeoutHandle !== null) clearTimeout(this.timeoutHandle)
    this.timeoutHandle = null;
  }
}
