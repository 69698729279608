










import { Component, Prop, Vue } from "vue-property-decorator";
import { Service, Transaction } from "@/npservice/service";
import TransactionStatus from "@/components/TransactionStatus.vue";

@Component({
  components: { TransactionStatus }
})
export default class LastTransaction extends Vue {
  @Prop() customerId!: number;

  transaction: Transaction | null = null;
  loading = true

  async mounted() {
    if( this.customerId ) {
      this.transaction = (await Service.getCustomerLastTransaction(this.customerId)) ?? null;
      this.loading = false;
    }
  }
}
