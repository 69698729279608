










import {Vue, Component, Prop } from "vue-property-decorator";
import { Service } from "@/npservice/service";

@Component
export default class CustomerLink extends Vue{
  @Prop({default: null}) customerId!: number | null;
  linkText: string | null = null

  async mounted() {
    if( this.customerId ) {
      const cc = await Service.getCustomer(this.customerId)
      this.linkText = `${cc.lastName}, ${cc.firstName} ${cc.middleName ?? ''}`
    }
  }
}
