






























import { Component, Vue } from "vue-property-decorator";
import { Registration, Service } from "@/npservice/service";
import AdminBar from "@/components/AdminBar.vue";
import { relativeShortDateTime } from "@/icodici/date_formats";
import dayjs from "dayjs";
import { infoToast } from "@/icodici/toasts";

@Component({
  components: { AdminBar }
})
export default class Registrations extends Vue {

  registrations: Registration[] | null = null
  documentUrls = Array<string|null>();

  fd(unixTime: number | null): string {
    return unixTime == null ? "" : relativeShortDateTime(dayjs.unix(unixTime))
  }

  registrationUrl(id: number): string {
    return `${Service.serviceRoot}registrations/${id}/best_face_image`;
  }

  async deleteRegistration(id: number) {
    const r = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to destroy registration ${id}? This operation may disrupt registrations in progress and damage customer data integrity, and it can not be undone.`
    );
    if( r == true ) {
      await Service.deleteRegistration(id);
      infoToast(this, "Registration record has been deleted", "successful deletion")
      this.reload()
    }
  }

  reload() {
    Service.getRegistrations().then(rr => {
      this.registrations = rr;
      for (const r of this.registrations) {
        Service.getRegistrationDocumentUrl(r.id).then(url => {
          Vue.set(this.documentUrls, r.id, url);
          console.log("set url to ", r.id);
        });
      }
    });
  }

  mounted() {
    this.reload();
  }
}
