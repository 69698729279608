















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WaitScreen extends Vue {
  @Prop({default: false}) show!: boolean;
  @Prop({default: 0}) progress!: number;
  @Prop() title!: string;
  @Prop({default: null}) message!: string | null;
}
