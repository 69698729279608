



























import { Component, Vue } from "vue-property-decorator";
import SkeletonText from "@/components/SkeletonText.vue";
import { Service, ServiceSettings } from "@/npservice/service";
import AdminBar from "@/components/AdminBar.vue";
import Propline from "@/components/propline.vue";
import Cliptext from "@/components/cliptext.vue";
import { EmitterHandle } from "@/icodici/Emitter";

@Component({
  components: { Cliptext, Propline, AdminBar, SkeletonText }
})
export default class Admin extends Vue {

  settings: ServiceSettings | null = null;
  private loginHandler!: EmitterHandle;

  async mounted() {
    this.loginHandler = Service.loggedInState.addListenerAndNotify(on => {
      if(on) Service.getSettings().then(s => this.settings = s)
    });
  }

  beforeDestroy() {
    this.loginHandler?.unsubscribe();
  }
}
