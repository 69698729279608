










import { Component, Prop, Vue } from "vue-property-decorator";
import { isTerminalStatus, Service, Transaction } from "@/npservice/service";

@Component({})
export default class LastTransaction extends Vue {
  @Prop() transactionId!: number;
  @Prop({default: true, type: Boolean}) showAmount!: boolean;

  transaction: Transaction | null = null;
  isFinal = true


  async update() {
    this.transaction = (await Service.getTransaction(this.transactionId))?.transaction ?? null;
    if( this.transaction !== null ) {
      this.isFinal = isTerminalStatus(this.transaction.status);
      if( !this.isFinal )
        setTimeout(()=>this.update(), 500)
    }
  }

  async mounted() {
    if( this.transactionId ) {
      await this.update()
    }
  }
}
