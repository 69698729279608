

































import { Component, Vue } from "vue-property-decorator";
import AdminBar from "@/components/AdminBar.vue";
import { Service, ServiceSettings } from "@/npservice/service";
import { infoToast, reportErrorToast } from "@/icodici/toasts";
import WaitScreen from "@/components/WaitScreen.vue";
@Component({
  components: { WaitScreen, AdminBar }
})

export default class Settings extends Vue{

  settings: ServiceSettings | null = null;
  disableUpdate = false
  wait = false

  isUrlGood() {
    const x = this.settings?.fflServiceUrl
    if( !x ) return false
    return x.length > 5 && x.startsWith("http://") || x.startsWith("https://")
  }

  canUpdate() {
    return !this.disableUpdate && this.isUrlGood() && this.settings?.fflServiceLogin != "" &&
        this.settings?.fflServicePassword != "" && this.settings?.onboardingToken != "" &&
        this.settings?.terminalToken != ""
  }

  async updateSettings() {
    this.disableUpdate = true
    try {
      this.wait = true;
      await reportErrorToast(this, "failed to update settings", async () => {
        await Service.saveSettings(this.settings!)
        infoToast(this, "settings have been updated", "success")
      });
      this.disableUpdate = false
    }
    finally {
      this.wait = false
    }
  }

  async mounted() {
    this.settings = await Service.getSettings()
  }

}
