
export class StoredValue<T> {

  private cacheLoaded = false;
  private cachedValue?: T;

  constructor(public name: string,
              private defaultValue: T | undefined,
              private storage = localStorage) {
  }

  get value(): T | undefined {
    if( !this.cacheLoaded ) {
      const s = this.storage.getItem(this.name);
      try {
        if (s != null)
          this.cachedValue = JSON.parse(s);
        else
          this.cachedValue = this.defaultValue;
      }
      catch(e) {
        console.warn(`failed to restore stared value(${this.name}), using default: ${e}`);
        this.cachedValue = this.defaultValue;
      }
      this.cacheLoaded = true;
    }
    return this.cachedValue;
  }

  set value(newValue: T|undefined) {
    if( newValue !== undefined ) {
      this.storage.setItem(this.name, JSON.stringify(newValue));
      this.cachedValue = newValue;
      this.cacheLoaded = true;
    }
    else {
      this.storage.removeItem(this.name);
      this.cachedValue = this.defaultValue;
      this.cacheLoaded = true;
    }
  }

  clear(): void {
    this.value = undefined;
  }
}
