



























import { Component, Vue } from "vue-property-decorator";
import { Service, TransactionData } from "@/npservice/service";
import AdminBar from "@/components/AdminBar.vue";
import { relativeShortDateTime } from "@/icodici/date_formats";
import CustomerLink from "@/components/CustomerLink.vue";

@Component({components: { CustomerLink, AdminBar}})
export default class Transactions extends Vue{
  transactions = Array<TransactionData>()
  lastId: number | null = null
  noMore = false
  isEndVisible = true

  async loadChunk() {
    const chunk = await Service.getTransactions(this.lastId)
    if( chunk.length == 0 ) //&& this.lastId !== null )
      this.noMore = true
    else {
      for (const t of chunk) {
        if (this.lastId === null || t.transaction.id < this.lastId) this.lastId = t.transaction.id;
        this.transactions.push(t)
      }
    }
  }

  async onScrolledToEnd(isVisible: boolean) {
    this.isEndVisible = isVisible;
    console.log("LC-2", isVisible, this.noMore)
    while( this.isEndVisible && !this.noMore )
      await this.loadChunk()
  }

  ft(unixTime: number): string {
    return relativeShortDateTime(unixTime)
  }

  // mounted() {
  //   this.loadChunk()
  // }
}
