









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SkeletonText extends Vue {
  @Prop({default: undefined}) value!: any;
}

