import dayjs from "dayjs";

dayjs.locale(window.navigator.language);

export function relativeShortDateTime(time: number | dayjs.Dayjs | Date): string {
  let utime;
  if( time instanceof Date) utime = time.getTime()
  else if( typeof(time) != 'number' ) utime = (time as dayjs.Dayjs).unix()
  else utime = time

  const now = (new Date()).getTime() / 1000;
  const d = dayjs.unix(utime)

  const days = Math.floor((now - utime) / (60 * 60 * 24));
  if (days < 4)
    return d.format('ddd HH:mm:ss')
  return d.format('DD.MM.YY HH:mm:ss')
}

// export function relativeShortDateTime(d: Date): string {
//   const datePart = relativeShortDate(d);
//   const m = moment(d);
//   return datePart.length == 0 ? m.format("HH:MM:SS") : `${datePart}, ${m.format('HH:MM')}`;
// }
