/**
 * The state that emits an event on change. Behaves much like the [[Emitter]] expect that it has a changeable
 * [[state]] attribute, with somewhat changed logic:
 *
 * - [[fire]] will do nothing if its value does not change the state, otherwise it changes the state and
 *   fires the event.
 * - assignment to [[state]] will fire an event if it has actually been changed.
 * - it has
 *
 * In the rest it is a drop-in replacement for an [[Emitter]]
 */
import { Emitter, EmitterEventListener, EmitterHandle } from "@/icodici/Emitter";

export default class StateEmitter<T> extends Emitter<T> {

  private _status: T;

  /**
   * return current state
   */
  get status(): T {
    return this._status;
  }

  /**
   * same as [[fire]]: if the value is different from the current state, updates it ans fires an event.
   * @param value
   */
  set status(value: T) {
    this.fire(value)
  }

  /**
   * Create the StateEmitter with a specified initial state. to change the state and fire events then
   * fire events with [[fire]] or change [[state]] directly.
   * @param initialState
   */
  constructor(initialState: T) {
    super();
    this._status = initialState;
  }

  /**
   * Add listener to the list and immediately notify it about current state. E.g. calls `lr(this.state)` and
   * registers it in the listener queue. See [[Emitter.listener]] for details. This way it is possible
   * to put all state-aware code in a listener and not to care about initial state as it will be fired
   * on add.
   *
   * @param lr
   * @return handle to unsubscribe this listener.
   */
  addListenerAndNotify(lr: EmitterEventListener<T>): EmitterHandle {
    lr(this._status);
    return this.addListener(lr);
  }

  /**
   * Changes [[state]] and fires an event if `newState` is different from current state, otherwise does nothing.
   * The [[state]] value is changed to `newState` _before the invocation of listeners_.
   * @param newState
   */
  fire(newState: T) {
    if (this._status != newState) {
      this._status = newState;
      super.fire(newState);
    }
  }

}
