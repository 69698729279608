







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Propline extends Vue{
  @Prop({default: ''}) className!: string;
  @Prop() name!: string;
}
