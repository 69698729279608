










































import { Component, Vue } from "vue-property-decorator";
import { Customer, Service, Transaction } from "@/npservice/service";
import AdminBar from "../components/AdminBar.vue";
import WaitScreen from "../components/WaitScreen.vue";
import Propline from "@/components/propline.vue";
import TransactionStatus from "@/components/TransactionStatus.vue";
import { relativeShortDateTime } from "@/icodici/date_formats";
import dayjs from "dayjs";
import { errorToast, warningToast } from "@/icodici/toasts";

@Component({
  components: { TransactionStatus, Propline, WaitScreen, AdminBar }
})
export default class CustomerTransactions extends Vue{

  customer: Customer | null = null;
  transactions: Transaction[] | null = null;
  videoUrl: string | null = null;
  disableDelete = false;
  isCheckingDossier = true;
  dossier: any | null = null;

  fd(unixTime: number): string {
    return relativeShortDateTime(dayjs.unix(unixTime))
  }

  async deleteCustomer() {
    if( this.customer ) {
      const r = await this.$bvModal.msgBoxConfirm(
          "Are you sure you want to destory this customer, all its transaction and registration records and " +
          "dossier in FFL service? This operation can not be undone"
      );
      if (r === true) {
        try {
          this.disableDelete = true
          await Service.deleteCustomer(this.customer?.id)
          warningToast(this.$parent, "Customer has been deleted", "Successful deletion")
          this.$router.back()
        }
        catch(x) {
          errorToast(this,x.toString(), "Deletion failed");
          this.disableDelete = false;
        }
      }
    }
  }

  async mounted() {
    try {
      this.customer = await Service.getCustomer(+this.$route.params['id'])
      Service.getDossier(this.customer.id).then(d => {
        this.isCheckingDossier = false;
        this.dossier = d;
      })
      this.transactions = await Service.getCustomerTransactions(this.customer.id)
      // Service.getCustomerVideo(this.customer.id).then(x => this.videoUrl = x)
    }
    catch(x) {
      this.$router.replace("/customers")
      errorToast(this.$parent, "Customer profile could not be found", "Error accessing customer data")
    }
  }

}
