
























import { Component, Vue } from "vue-property-decorator";
import { Service } from "@/npservice/service";
import { EmitterHandle } from "@/icodici/Emitter";
import { reportErrorToast } from "@/icodici/toasts";

@Component({})
export default class LoggedIn extends Vue {

  loggedIn: boolean | null = null
  loginName = "admin"
  password = ""
  passwordState: boolean | null = null
  nextLoginSecondsLeft = 0

  private handler: EmitterHandle | null = null;
  private timerHandler: number | null = null;

  canLogin(): boolean {
    return this.loginName != "" && this.password != "" && this.nextLoginSecondsLeft <= 0;
  }

  tryLogin() {
    reportErrorToast(this, "unexpected error", async () => {
      const result = await Service.signIn(this.loginName, this.password)
      if( result != "connected" ) {
        this.nextLoginSecondsLeft = result
        this.passwordState = false
      }
      else {
        this.nextLoginSecondsLeft = 0
        this.passwordState = null
      }
    });
  }

  mounted() {
    this.handler = Service.loggedInState.addListenerAndNotify((ok) => {
      this.loggedIn = ok
    })
    this.passwordState = null
    this.nextLoginSecondsLeft = 0
    this.timerHandler = setInterval(() => {
      if( this.nextLoginSecondsLeft > 0 ) this.nextLoginSecondsLeft--
    }, 1000);
  }

  beforeDestoroy() {
    if( this.timerHandler ) clearInterval(this.timerHandler);
  }
}
