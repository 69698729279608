






























import { Component, Vue } from "vue-property-decorator";
import AdminBar from "../components/AdminBar.vue";
import { Customer, Service, Transaction } from "@/npservice/service";
import { relativeShortDateTime } from "@/icodici/date_formats";
import dayjs from "dayjs";
import TransactionStatus from "@/components/LastTransaction.vue";

@Component({
  components: { TransactionStatus, AdminBar }
})
export default class Customers extends Vue {

  customers: Customer[] | null = null;
  lastOperations = new Map<number, Transaction>();
  pattern = ""

  get name(): string {
    return "Recently added/updated customers"
  }

  fd(unixTime: number): string {
    return relativeShortDateTime(dayjs.unix(unixTime))
  }

  clearSearch() {
    this.pattern = "";
    this.reload();
  }

  // checkKey(ev: KeyboardEvent) {
  //   console.log(ev)
  //   if(  ev.)
  // }

  onUpdateSearch() {
    console.log("updated: "+this.pattern);
    this.reload();
  }

  async reload() {
    this.customers = await Service.getCustomers(this.pattern);
  }

  mounted() {
    this.pattern = this.$route.query["q"]?.[0] ?? "";
    this.reload();
  }

}
