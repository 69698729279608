import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Initialize from "@/views/Initialize.vue";
import Admin from "@/views/admin.vue";
import Settings from "@/views/Settings.vue";
import Transactions from "@/views/Transactions.vue";
import TransactionDetails from "@/views/TransactionDetails.vue";
import Customers from "@/views/Customers.vue";
import CustomerTransactions from "@/views/CustomerTransactions.vue";
import Registrations from "@/views/Registrations.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/initialize',
    name: 'initialize',
    component: Initialize
  }, {
    path: '/admin',
    name: 'admin',
    component: Admin
  }, {
    path: '/settings',
    name: 'settings',
    component: Settings
  }, {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions
  }, {
    path: '/customers',
    name: 'Customers',
    component: Customers
  }, {
    path: '/transaction/:id',
    name: 'Transaction',
    component: TransactionDetails
  }, {
    path: '/customers/:id',
    name: 'Details',
    component: CustomerTransactions
  }, {
    path: '/registrations',
    name: 'Registrations',
    component: Registrations
  }, {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
