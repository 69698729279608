import StateEmitter from "@/icodici/StateEmitter";
import { ApiError } from "@/npservice/errors";
import { StoredValue } from "@/icodici/StoredValue";

export type SimpleParamType = number | string | boolean | null;
export type CollectionParamType =
  Array<SimpleParamType>
  | Record<string, SimpleParamType>;
export type ParamType = SimpleParamType | CollectionParamType;

export interface UserRecord {
  login: string;
  password: string;
  canAdmin: boolean;
  canManageClients: boolean,
  createdAt: number
}

export interface LoginResult {
  user?: UserRecord;
  authToken?: string;
  nextLoginAttemptAt?: number;
}

export type TransactionStatus =
  "NEW"
  | "IN_PROGRESS"
  | "BAD_FACE"
  | "PROFILE_NOT_FOUND"
  | "EXECUTING"
  | "ACCEPTED"
  | "REJECTED"
  | "REFUNDED"
  | "FAILED";

export function isTerminalStatus(t: TransactionStatus): boolean {
  switch (t) {
    case "REFUNDED":
    case "REJECTED":
    case "PROFILE_NOT_FOUND":
    case "FAILED":
    case "BAD_FACE":
    case "ACCEPTED":
      return true;
  }
  return false
}

export interface Transaction {
  id: number;
  status: TransactionStatus;
  amount: string;
  nextPollAt: number;
  commentText: string | null;
  createdAt: number;
  updatedAt: number;
  dossierId: number | null;
}

export interface LogRecord {
  id: number;
  transactionId: number | null;
  customerId: number | null;
  userId: number | null;
  terminalId: number | null;
  operation: string;
  text: string;
  createdAt: number;
  jsonData: any | null;
}

export interface Customer {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  dossierId: string;
  isBlocked: boolean;
  createdAt: number;
  balance: string;
  lastCardDigits: string;
}

export interface TransactionData {
  customerId: number | null;
  transaction: Transaction
}

async function decodeResponse<T>(r: Response): Promise<T> {
  const bodyText = await r.text()
  if (r.status != 200) {
    let data: any = {};
    try {
      data = JSON.parse(bodyText)
    } catch (e) {
      console.error("can't parse result body", bodyText, e)
      throw new ApiError(r.status, "can't parse answer")
    }
    console.log("<<<----- ", data)
    throw new ApiError(r.status, data)
  }
  try {
    console.log("<<<< " + bodyText)
    return JSON.parse(bodyText) as T;
  } catch (e) {
    console.error("can't parse non-error result", bodyText)
    throw new ApiError(600, { errorCode: "bad_server_answer" })
  }
}

export type ServiceState = "NOT_INITIALIZED" | "PAUSED" | "READY";

export interface ServiceSettings {
  fflServiceUrl: string;
  fflServiceLogin: string;
  fflServicePassword: string;
  terminalToken: string;
  onboardingToken: string;
}

export interface Registration {
  id: number;
  customerId: number | null;
  customerSelfieId: number | null;
  faceId: string;
  bestFaceDetectionId: string | null;
  dossierId: string | null;
  createdAt: number;
  completedAt: number | null;
  hasDocumentData: boolean;
}

class CachedCustomer {

  private _value!: Promise<Customer>;
  private loadedAt?: number;

  constructor(public readonly id: number) {
    this.load();
  }

  refresh() {
    this.load();
  }

  private load() {
    this._value = Service._getCustomer(this.id);
    this._value.then(_ => this.loadedAt = Date.now());
  }

  get value(): Promise<Customer> {
    if( this.loadedAt !== undefined && (Date.now() - this.loadedAt) > 5000)
      this.load();
    return this._value;
  }

}

export class Service {

  static serviceState = new StateEmitter<ServiceState | null>(null)
  static loggedInState = new StateEmitter<boolean>(false)
  private static userToken = new StoredValue<string>("userToken", undefined)

  static version = ""

  static readonly isDebug = window.location.host.startsWith("localhost")
  static readonly serviceRoot = Service.isDebug ? "http://localhost:8090/api/" : "/api/"
  // static serviceRoot = Service.isDebug ? "https://service.ntechlab.com/api/" : "/api/"

  private static get authToken(): string | undefined {
    return this.userToken.value
  }

  private static set authToken(value: string | undefined) {
    this.userToken.value = value
    this.loggedInState.status = !(value === "" || value === undefined);
  }

  private static get headers(): Record<string, string> {
    const hh: Record<string, string> = {
      'Content-Type': 'application/json; charset=UTF-8',
      // 'Accept': 'application/json'
    }
    if (this.authToken)
      hh['Authorization'] = `Bearer ${this.authToken}`
    return hh;
  }

  public static async videoUrl(id: number): Promise<string> {
    return (await this.get<{ videoUrl: string }>(`payments/${id}/video_url`)).videoUrl;
  }

  public static async get<T>(path: string): Promise<T> {
    console.log(">>>  GET " + this.serviceRoot + path)
    const r = await fetch(this.serviceRoot + path, {
      method: 'get',
      headers: this.headers,
      mode: "cors"
    })
    return decodeResponse(r)
  }

  public static async post<T>(path: string, params: any): Promise<T> {
    console.log(">>> POST " + this.serviceRoot + path)
    const r = await fetch(this.serviceRoot + path, {
      method: 'post',
      headers: this.headers,
      body: JSON.stringify(params),
      mode: "cors"
    })
    return decodeResponse(r)
  }

  static async refreshState() {
    const v =
      await this.get<{ service: string, version: string, status: ServiceState, loggedIn: boolean }>("version")
    if (v.service !== 'ffl_payments')
      throw new Error("Wrong service connected: " + v.service);
    console.log(v)
    this.version = v.version
    this.serviceState.status = v.status
    if (v.status == "NOT_INITIALIZED") this.authToken = undefined
    if (!v.loggedIn && this.isSignedIn)
      this.authToken = undefined
  }

  static async prepare() {
    this.loggedInState.status = this.authToken !== undefined
    await this.refreshState()
    if (this.serviceState.status == "NOT_INITIALIZED") this.authToken = undefined;
  }

  static async initialize(login: string, password: string, createAdminToken: string): Promise<void> {
    const result = await this.post<{ user: UserRecord }>("users/register", {
      login, password, createAdminToken,
      canAdmin: true,
      canManageClients: true
    })
    console.log(result)
  }

  static async signIn(login: string, password: string): Promise<"connected" | number> {
    const result = await this.post<LoginResult>("users/login", { login, password })
    console.log(result)
    if (result.authToken) {
      this.authToken = result.authToken;
      return "connected";
    } else {
      this.authToken = undefined
      if (!result.nextLoginAttemptAt) return 0;
      return result.nextLoginAttemptAt - Date.now() / 1000
    }
  }

  static async getTransactions(before: number | null = null): Promise<Array<TransactionData>> {
    let url = "payments?limit=15"
    if (before) url += `&before=${before}`;
    return (await this.get<{ transactions: Array<TransactionData> }>(url)).transactions;
  }

  static async getCustomerTransactions(customerId: number, limit = 50, before: number | null = null,): Promise<Transaction[]> {
    let url = `customers/${customerId}/transactions?limit=${limit}`
    if (before !== null) url += `&before=${before}`
    return (await this.get<{ transactions: Array<TransactionData> }>(url)).transactions
      .map(t => t.transaction);
  }

  static async getCustomerLastTransaction(customerId: number): Promise<Transaction | undefined> {
    return (await this.getCustomerTransactions(customerId, 1))[0];
  }

  static async getTransaction(id: number): Promise<TransactionData> {
    return (await this.get<{ transaction: TransactionData }>(`/payments/${id}/data`)).transaction;
  }

  static async getTransactionLogs(id: number, afterId = 0): Promise<Array<LogRecord>> {
    return (await this.get<{ log: Array<LogRecord> }>(`/payments/${id}/log?afterId=${afterId}`)).log;
  }

  static async restartTransaction(id: number): Promise<void> {
    await this.get<{}>(`/payments/${id}/restart`);
  }

  static get isSignedIn(): boolean {
    return this.authToken !== undefined && this.authToken !== ""
  }

  static async signOut() {
    if (this.isSignedIn) {
      this.get("/users/logout").then(r => console.log("logout result:", r));
      this.authToken = undefined
    }
  }

  static async getSettings(): Promise<ServiceSettings> {
    return await this.get("/service_settings")
  }

  static async saveSettings(ss: ServiceSettings): Promise<void> {
    await this.post("/service_settings", ss)
  }

  static async getCustomers(pattern: string | null = null): Promise<Customer[]> {
    let url = "customers";
    if (pattern !== null && pattern != "") {
      url += `?q=${encodeURIComponent(pattern)}`;
    }
    console.log("rew: " + url)
    return (await this.get<{ customers: Customer[] }>(url)).customers
  }

  private static customers = new Map<number, CachedCustomer>()

  static async _getCustomer(id: number): Promise<Customer> {
    return (await this.get<{ customer: Customer }>(`customers/${id}`)).customer
  }

  static async getCustomer(id: number): Promise<Customer> {
    let cc = this.customers.get(id);
    if( !cc ) {
      cc = new CachedCustomer(id);
      this.customers.set(id,cc);
    }
    console.log(id, 4,cc)
    return await cc.value;
  }

  static async deleteCustomer(id: number): Promise<void> {
    await this.post(`customers/${id}/delete`, {})
  }

  static async getCustomerVideo(id: number): Promise<string> {
    return (await this.get<{ videoUrl: string }>(`customers/${id}/video_url`)).videoUrl
  }

  static async getDossier(id: number): Promise<any|null> {
    // todo: introduce dossier structure
    return (await this.get<{dossier?: any}>(`customers/${id}/check_dossier`)).dossier;
  }

  static async getRegistrationDocumentUrl(id: number): Promise<string> {
    return (await this.get<{ documentImageUrl: string }>(`registrations/${id}/document_image_url`)).documentImageUrl
  }

  static async getRegistrations(): Promise<Registration[]> {
    return (await this.get<{ registrations: Registration[] }>("registrations")).registrations
  }

  static async deleteRegistration(id: number): Promise<void> {
    await this.post(`registrations/${id}/delete`, {})
  }
}

Service.prepare();

export function isDebug<T>(debugValue: T, prodValue: T): T {
  return Service.isDebug ? debugValue : prodValue;
}
