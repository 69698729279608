


















import { Component, Vue } from "vue-property-decorator";
import { isDebug, Service } from "@/npservice/service";
import { errorToast, infoToast } from "@/icodici/toasts";

@Component
export default class Initialize extends Vue {

  adminLogin = "admin"
  adminPassword = ""
  adminToken = isDebug("FikR=UUD-O37_Wkmewh9", "")

  canCreate(): boolean {
    return this.adminLogin != "" && this.adminPassword != "" && this.adminToken != ""
  }

  async doCreate() {
    console.log("ons")
    if (this.canCreate()) {
      try {
        await Service.initialize(this.adminLogin, this.adminPassword, this.adminToken)
        infoToast( this.$parent, "System initialized, admin user created", "Initialization done")
        await this.$router.replace("/")
      } catch(e) {
        errorToast(this, e.toString(), "Initialization failed");
      }
    }
  }

}
