








import { Component, Prop, Vue } from "vue-property-decorator";
import { Passwords } from "@/icodici/Passwords";
import { errorToast } from "@/icodici/toasts";

@Component
export default class Cliptext extends Vue{
  @Prop() text!: string;

  uniqueId = Passwords.randomId()
  showTooltip = false

  doCopy() {
    console.log("doc!")
    try {
      navigator.clipboard.writeText(this.text)
      this.showTooltip = true
      this.$root.$emit('bv::show::tooltip', this.uniqueId)
    }
    catch(e) {
      errorToast(this, e.toString(), "Clipboard operation failed")
    }
  }
}
