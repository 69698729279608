

























import { Component, Vue } from 'vue-property-decorator';
import { Service } from "@/npservice/service";
import { EmitterHandle } from "@/icodici/Emitter"; // @ is an alias to /src

@Component({
  components: {
  },
})
export default class Home extends Vue {
  status: String | null = null

  private handler?: EmitterHandle;
  private timerHandler?: number;

  mounted() {
    this.handler = Service.serviceState.addListenerAndNotify((s)=> this.status = s);
    Service.refreshState()
    this.timerHandler = setInterval(()=>{Service.refreshState()}, 10000)
  }

  beforeDestroy() {
    this.handler?.unsubscribe()
    if( this.timerHandler ) clearInterval(this.timerHandler)
  }
}
